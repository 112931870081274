import { useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Menu from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { Code, Person, Work } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material"; // For responsive design

interface MenuItem {
  listIcon: JSX.Element;
  listText: string;
  listPath: string;
}
const menuItems: MenuItem[] = [
  { listIcon: <Menu/>, listText: "", listPath: "" },
  { listIcon: <Person />, listText: "About", listPath: "/" },
  { listIcon: <Work />, listText: "Resume", listPath: "/resume" },
  { listIcon: <Code />, listText: "Projects", listPath: "/projects" }
];

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [open, setOpen] = useState(false);

  const sideList = () => (
    <Box sx={{ width: isMobile ? 240 : 300 }}>
      <Divider />
      <List>
         {menuItems.map((item, i) => (
          <ListItemButton 
            key={i}
            onClick={() => setOpen(false)}
            component={Link}
            to={item.listPath}
          >
            <ListItemIcon             
              sx={{ "& .MuiSvgIcon-root": { fill: theme.palette.primary.main} }}>
              {item.listIcon}
            </ListItemIcon>
            <ListItemText 
              primary={item.listText}
              primaryTypographyProps={{ fontSize: isMobile ? "1rem" : "1.25rem" }} // Responsive text size
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <Box component="nav">
        <AppBar position="static" sx={{ padding: isMobile ? "8px" : "16px" }}> 
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton 
              onClick={() => setOpen(true)} 
              edge="start" 
              sx={{ marginRight: isMobile ? "8px" : "16px" }}
            >
              <Menu color="secondary" sx={{ fontSize: isMobile ? "1.5rem" : "2rem" }} /> {/* Adjust menu icon size */}
            </IconButton>
            <Typography 
              variant="h5" 
              sx={{ fontSize: isMobile ? "1.25rem" : isTablet ? "1.5rem" : "2rem" }} // Responsive font size for title
            >
              Cecilia La Place
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer open={open} anchor="left" onClose={() => setOpen(false)}>
        {sideList()}
      </Drawer>
    </Box>
  );
};


export default Navbar;