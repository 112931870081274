import React from "react";
import Typography from "@mui/material/Typography";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Box from "@mui/material/Box";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

  // const useStyles = makeStyles((theme) => ({
  //   mainContainer: {
  //     background: "#233",
  //   },
  //   timeLine: {
  //     position: "relative",
  //     padding: "1rem",
  //     margin: "o auto",
  //     "&:before": {
  //       content: "''",
  //       position: "absolute",
  //       height: "100%",
  //       border: "1px solid tan",
  //       right: "40px",
  //       top: 0,
  //     },
  //     "&:after": {
  //       content: "''",
  //       display: "table",
  //       clear: "both",
  //     },
  //     [theme.breakpoints.up("md")]: {
  //       padding: "2rem",
  //       "&:before": {
  //         left: "calc(50% - 1px)",
  //         right: "auto",
  //       },
  //     },
  //   },
  //   timeLineItem: {
  //     padding: "1rem",
  //     borderBottom: "2px solid tan",
  //     position: "relative",
  //     margin: "1rem 3rem 1rem 1rem",
  //     clear: "both",
  //     "&:after": {
  //       content: "''",
  //       position: "absolute",
  //     },
  //     "&:before": {
  //       content: "''",
  //       position: "absolute",
  //       right: "-0.625rem",
  //       top: "calc(50% - 5px)",
  //       borderStyle: "solid",
  //       borderColor: "tomato tomato transparent transparent",
  //       borderWidth: "0.625rem",
  //       transform: "rotate(45deg)",
  //     },
  //     [theme.breakpoints.up("md")]: {
  //       width: "44%",
  //       margin: "1rem",
  //       "&:nth-of-type(2n)": {
  //         float: "right",
  //         margin: "1rem",
  //         borderColor: "tan",
  //       },
  //       "&:nth-of-type(2n):before": {
  //         right: "auto",
  //         left: "-0.625rem",
  //         borderColor: "transparent transparent tomato tomato",
  //       },
  //     },
  //   },
  //   timeLineYear: {
  //     textAlign: "center",
  //     maxWidth: "9.375rem",
  //     margin: "0 3rem 0 auto",
  //     fontSize: "1.8rem",
  //     color: "#fff",
  //     background: "tomato",
  //     lineHeight: 1,
  //     padding: "0.5rem 1rem",
  //     "&:before": {
  //       display: "none",
  //     },
  //     [theme.breakpoints.up("md")]: {
  //       textAlign: "center",
  //       margin: "0 auto",
  //       "&:nth-of-type(2n)": {
  //         float: "none",
  //         margin: "0 auto",
  //       },
  //       "&:nth-of-type(2n):before": {
  //         display: "none",
  //       },
  //     },
  //   },
  //   heading: {
  //     color: "tomato",
  //     padding: "3rem 0",
  //     textTransform: "uppercase",
  //   },
  //   subHeading: {
  //     color: "#fff",
  //     padding: 0,
  //     textTransform: "uppercase",
  //   },
  //   body1: {
  //     color: "tomato",
  //   },
  //   subtitle1: {
  //     color: "tan",
  //   },
  // }));
interface experience {
  date_start: Date,
  date_end?: Date,
  title: string;
  company: string;
  description: string;
  type: string;
}
const experiences:experience[] = [
  {"date_start": new Date("2023-07"), "date_end": new Date("2023-09"), "title": "Systems Engineeering Intern", "company": "D.E. Shaw", "description": "", "type": "work"}, 
  {"date_start": new Date("2023-02"), "title": "MLH Coach", "company": "Major League Hacking (MLH)", "description": "", "type": "work"}, 
  {"date_start": new Date("2022-07"), "date_end": new Date("2022-09"), "title": "Software Engineeering Intern", "company": "PayPal", "description": "", "type": "work"}, 
  {"date_start": new Date("2021-07"), "date_end": new Date("2021-09"), "title": "Software Engineeering Intern", "company": "PayPal", "description": "", "type": "work"},
  {"date_start": new Date("2020-07"), "date_end": new Date("2020-09"), "title": "Software Engineeering Intern", "company": "PayPal", "description":"", "type": "work"},
  {"date_start": new Date("2018-07"), "date_end": new Date("2019-09"), "title": "Software Engineeering Intern", "company": "Garmin", "description":"", "type": "work"},
  {"date_start": new Date("2024-06"), "date_end": new Date("2024-09"), "title": "Graduate Research Assistant", "company": "Arizona State University", "description": "", "type": "research"}, 
  {"date_start": new Date("2022-02"), "date_end": new Date("2024-1"), "title": "Graduate Research Assistant", "company": "Arizona State University", "description": "", "type": "research"},
  {"date_start": new Date("2024-02"), "date_end": new Date("2024-06"), "title": "Graduate Research Assistant", "company": "Arizona State University", "description": "", "type": "research"},
  {"date_start": new Date("2022-09"), "title": "Independent Contractor", "company": "Quality Measures, LLC", "description": "", "type": "research"},
  {"date_start": new Date("2019-09"), "date_end": new Date("2021-09"), "title": "Graduate Research Assistant", "company": "Arizona State University", "description": "", "type": "research"},
  {"date_start": new Date("2021-09"), "date_end": new Date("2022-06"), "title": "Graduate Teaching Assistant", "company": "Arizona State University", "description": "", "type": "teaching"},]

const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', { month: 'long', year: 'numeric' });
const orderedTimeline = experiences.sort((a, b) => (a.date_start < b.date_start) ? 1 : -1);

const getDot = (exp: experience): React.JSX.Element => {
  if (exp.date_end && exp.type==="work") {
    return <TimelineDot variant="filled" color="primary"/>
  } else if ((exp.type === "research" || exp.type === "teaching") && exp.date_end) {
    return <TimelineDot variant="filled" color="secondary"/>
  } else {
    return <TimelineDot variant="outlined" color="secondary"/>
  }
}

const Resume = () => {
  // const classes = useStyles();
  return (
    <Box component="header" margin="5vh">
      <Typography variant="h4" align="center" >
        Working Experience
      </Typography>
    <Box>
      <Timeline position="alternate">
      {orderedTimeline.map((exp, i) => (
        <TimelineItem>
          <TimelineOppositeContent 
            sx={{ m:"auto 0"}}
            align="right"
            variant="body2"
            color="text.secondary">
            {formatter.format(exp.date_start)} - {(exp.date_end) ? formatter.format(exp.date_end) : "Present"}
            </TimelineOppositeContent>
          <TimelineSeparator>
          <TimelineConnector />
          {getDot(exp)}
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography>{exp.title}</Typography>
            <Typography>{exp.company}</Typography>
            <Typography>{exp.description}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>

    </Box>
    </Box>
  );
};

export default Resume;
