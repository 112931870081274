import { useTheme } from "@mui/material/styles"; // Import the useTheme hook
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Link } from 'react-router-dom';
import Twitter from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useMediaQuery } from "@mui/material"; // For responsive design


export default function Footer() {
    const theme = useTheme(); // Use the useTheme hook to get the theme object
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
    return (
        <BottomNavigation 
        sx={{ 
          position: "fixed", 
          bottom: 0, 
          left: 0, 
          right: 0,
          height: isMobile ? 56 : isTablet ? 64 : 72, // Adjust footer height dynamically
          justifyContent: "center" // Space items evenly for mobile/tablets
        }}
        >
                  <BottomNavigationAction 
            component={Link} 
            to="https://github.com/HalcyonAura" 
            icon={<GitHubIcon />} 
            sx={{ 
              "& .MuiSvgIcon-root": { 
                fill: theme.palette.primary.main, 
                fontSize: isMobile ? "1.2rem" : isTablet ? "1.5rem" : "2rem", // Adjust size
                "&:hover": { 
                  fill: theme.palette.primary.dark, 
                  fontSize: isMobile ? "1.4rem" : isTablet ? "1.7rem" : "2.2rem" // Hover size
                }
              } 
            }} 
          />
          <BottomNavigationAction 
            component={Link} 
            to="https://linkedin.com/in/cecilia-la-place" 
            icon={<LinkedInIcon />} 
            sx={{ 
              "& .MuiSvgIcon-root": { 
                fill: theme.palette.primary.main, 
                fontSize: isMobile ? "1.2rem" : isTablet ? "1.5rem" : "2rem",
                "&:hover": { 
                  fill: theme.palette.primary.dark, 
                  fontSize: isMobile ? "1.4rem" : isTablet ? "1.7rem" : "2.2rem" 
                }
              } 
            }} 
          />
          <BottomNavigationAction 
            component={Link} 
            to="https://twitter.com/HalcyonCode" 
            icon={<Twitter />} 
            sx={{ 
              "& .MuiSvgIcon-root": { 
                fill: theme.palette.primary.main, 
                fontSize: isMobile ? "1.2rem" : isTablet ? "1.5rem" : "2rem",
                "&:hover": { 
                  fill: theme.palette.primary.dark, 
                  fontSize: isMobile ? "1.4rem" : isTablet ? "1.7rem" : "2.2rem" 
                }
              } 
            }} 
          />
        </BottomNavigation> 
      );
    };
