import {useState, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import profile from "../assets/profile.jpg";
import { useTheme } from "@mui/material/styles"; // Import the useTheme hook


const subheader = ["learner", "programmer","crafter", "hacker", "maker", "researcher","teacher"]
function Identities(): JSX.Element {
  const [idx, setIdx] = useState<number>(0);
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    interval = setInterval(() => {
      if (idx === subheader.length - 1) setIdx(0);
      else setIdx((idx) => idx + 1);
    }, 1400);
    return () => clearInterval(interval!);
  }, [idx]);

  return <div aria-hidden="true" className="time">{"< " + subheader[idx] + " />"}</div>;
}

const About = () => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      boxShadow={2}
      borderRadius="25px"
      padding={{ xs: "1rem", sm: "2rem" }} // Responsive padding
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" }, // Responsive width
        height: { xs: "auto", sm: "55vh" }, // Responsive height
        textAlign: "center",
        backgroundColor: theme.palette.primary.main
      }}
    >
      <Avatar 
        alt="Cecilia La Place, she is wearing an ASU polo with shoulder length curly hair and wide black rimmed glasses." 
        src={profile} 
        sx={{ 
          margin: "auto", 
          height: { xs: "20vh", sm: "25vh" }, // Responsive height
          width: { xs: "20vh", sm: "25vh" } // Responsive width
        }}
      />
      <Typography 
        color="primary.contrastText" 
        variant="h4" // Adjusted to h4 for better scaling
        sx={{ 
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" } // Responsive font size
        }}
      >
        <Identities />
      </Typography>

      <Typography 
        color="primary.contrastText" 
        variant="h2" // Adjusted to h5 for better scaling
        sx={{ 
          fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" } // Responsive font size
        }}
      >
        Cecilia La Place
      </Typography>
      
      <Typography 
        color="secondary.contrastText" 
        variant="h4" // Adjusted to h6 for better scaling
        sx={{ 
          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" } // Responsive font size
        }}
      >
        Arizona State University
      </Typography>
      
      <Typography 
        color="secondary.contrastText" 
        variant="body1" // Adjusted to body1 for better scaling
        sx={{ 
          fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" } // Responsive font size
        }}
      >
        I'm an Engineering Education Ph.D. student studying hackathons and their communities. Check out my projects and resume in the side bar!
      </Typography>
    </Box>
  );
};

export default About;