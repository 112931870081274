import { useTheme } from "@mui/material/styles"; // Import the useTheme hook
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import project1 from "../assets/ParticipantPortal.png";
import project2 from "../assets/Book Dragon.png";
import project3 from "../assets/StickerBase.png";
import project4 from "../assets/Shivr.png";
import project5 from "../assets/TravelDash.png";
import project6 from "../assets/haccessible.png";
import Divider from '@mui/material/Divider';
import { Chip } from "@mui/material";

interface Project {
  name: string;
  description: string;
  image: string;
  ariaLabel: string;
  link: string;
  tech: string;
}

const projects: Project[] = [
  // {
  //   name: "Participant Portal",
  //   description: `Connecting participants with the projects they've given their data to.`,
  //   image: project1,
  //   ariaLabel:"Project icon depicts a magnifying glass over a database symbol.",
  //   link: "https://github.com/HalcyonAura/participant-portal",
  //   tech: "Ruby on Rails",
  // },
  // {
  //   name: "Book Dragon",
  //   description: `Tackling some missing features in Goodreads and the StoryGraph.`,
  //   image: project2,
  //   ariaLabel:"Project icon depicts a minimalistic book.",
  //   link: "https://github.com/HalcyonAura/BookDragon",
  //   tech: "NodeJS, Express, PostgreSQL, React"
  // },
  {
    name: "Stickerbase",
    description: `Manage your sticker collection and track all your new additions.`,
    image: project3,
    ariaLabel:"Project icon depicts a database symbol.",
    link: "https://github.com/HalcyonAura/stickerbase",
    tech: "React, sqlite3"
  },
  {
    name: "Shivr",
    description: `Bumble for sharks, keep an eye on your favorites.`,
    image: project4,
    ariaLabel:"Project icon depicts a shark fin rising above the water.",
    link: "https://github.com/HalcyonAura/Shivr",
    tech: "Flask, Python, HTML, CSS, JS"
  },
  {
    name: "TravelDash",
    description: `Have a collection of travel information for your next trip.`,
    image: project5,
    ariaLabel:"Project icon depicts a paper airplane flying through the air.",
    link: "https://github.com/HalcyonAura/TravelDash",
    tech: "Flask, Python, HTML, CSS"
  },
  {
    name: "Haccessible",
    description: `Showcase a more accessible hackathon website.`,
    image: project6,
    ariaLabel:"Project icon depicts an information bubble.",
    link: "https://github.com/HalcyonAura/Haccessible",
    tech: "HTML, CSS, JS"
  },
];

const Projects = () => {
  const theme = useTheme();

  return (
    <Box component="div" sx={{ flexGrow: 1, padding: "2rem" }}>
      <Grid 
        container 
        component="div" 
        spacing={{ xs: 2, md: 3 }} 
        alignItems="stretch"
      >
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid 
            item 
            xs={12} sm={6} md={4} lg={3} // More responsive grid sizes for various screen sizes
            key={i}
          >
            <Card 
              sx={{ 
                margin: "1rem auto", 
                padding: { xs: "0.5rem", sm: "1rem" }, // Adjust padding based on screen size
                borderColor: theme.palette.primary.light, 
                borderStyle: "solid", 
                borderWidth: "1px", 
                borderRadius: "25px",
                maxWidth: { xs: 345, sm: 345, lg:500 }, // Ensure card has a max width for better control on larger screens
                height: "100%" // Ensure equal card heights across grid items
              }}
            >
                <CardActions > 
                <Button 
                  size="large" 
                  color="primary" 
                  href={project.link} 
                  target="_blank"
                  sx={{  marginLeft: 'auto', fontSize: { xs: "1.0rem", sm: "1.2rem" } }} // Adjust button text size
                >
                  Repo
                </Button>
              </CardActions>
              <CardMedia
                component="img"
                alt={project.name}
                height="250px"
                image={project.image}
                aria-label={project.ariaLabel}
                sx={{ 
                  padding: { xs: "0.5rem 0", sm: "1em" }, // Adjust image padding for responsiveness
                  objectFit: "contain" 
                }}
              />
              <CardContent>
                <Typography 
                  color="primary" 
                  variant="h6" // Smaller typography for mobile
                  sx={{ fontSize: { xs: "1.5rem", sm: "1.8rem" } }} // Responsive font size
                  gutterBottom
                >
                  {project.name}
                </Typography>
                <Typography 
                  variant="body2" // Smaller text for description
                  color="textPrimary"
                  sx={{ fontSize: { xs: "1rem", sm: "1.2rem" } }} // Responsive body text size
                >
                  {project.description}
                </Typography>
                <Divider variant="middle" sx={{ margin: "1rem" }}>
                  <Chip label="Tech" size="small" color="secondary" /> {/* Smaller chip for mobile */}
                </Divider>
                <Typography 
                  variant="body2" 
                  color="textPrimary" 
                  align="center"
                  sx={{ fontSize: { xs: "2rem", sm: "1.2rem" } }} // Adjusted tech typography
                >
                  {project.tech}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};


export default Projects;