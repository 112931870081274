import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
//import Resume from "./components/Resume";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Resume from "./components/Resume";
//import Blog from "./components/Blog";
import './App.css';
import Footer from "./components/Footer";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";

// Create custom MUI theme with your preferred primary and secondary colors
const theme = createTheme({
  palette: {
    primary: {
      main: "#483d8b", // Replace with your custom primary color '#726DA8'
      contrastText: "#EBE9EC",
    },
    secondary: {
      main: '#807FB2', // Replace with your custom secondary color EBE9EC
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" Component={About} />
        <Route path="/resume" Component={Resume} />
        <Route path="/projects" Component={Projects} />
      </Routes>
      <Footer/>

    </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
